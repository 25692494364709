<template>
    <div id="home">
        <top-bar :list="topList" @toLocation="toLocation"></top-bar>
        <div style="height: 90px;"></div>
        <div id="home0">
            <img src="../assets/img/banner.png" alt="">
        </div>

        <!-- 产品展示 -->
        <div id="home1">
            <div class="title">产品展示</div>
            <div class="title1">PRODUCT DISPLAY</div>
            <div class="product">
                <div class="product-box" v-for="(item, i) in list" :key="i" @click="toDetail(i)">
                    <div class="product-logo">
                        <img :src="item.logo" alt="">
                    </div>
                    <div class="product-name">
                        {{ item.name }}
                    </div>
                    <div class="product-text">
                        {{ item.introduce }}
                    </div>
                    <div class="product-more">了解更多</div>
                </div>
            </div>
        </div>

        <!-- 关于我们 -->
        <div id="home2">
            <about-us></about-us>
        </div>

        <bottom-bar></bottom-bar>
    </div>
</template>
<script>
import topBar from '@/components/top-bar.vue'
import bottomBar from '@/components/bottom-bar.vue'
import aboutUs from '@/components/about-us.vue'
export default {
    components: {
        topBar,
        bottomBar,
        aboutUs
    },
    data() {
        return {
            topList: ['首页', '产品展示', '关于我们'],
            list: [{
                name: "AI智能写作助手",
                introduce: '您的快速，高效，智能创作伙伴',
                logo: require('../assets/img/Ai-zs.png')
            },
            {
                name: "AI绘画创业帮",
                introduce: '一键成画，AI助手，创意无限大！',
                logo: require('../assets/img/Ai-hh.png')
            },
            {
                name: "AI绘画创业帮手",
                introduce: '一键成画，AI助手，创意无限大！',
                logo: require('../assets/img/Ai-hh.png')
            }],
        }
    },
    onLoad() {

    },
    methods: {
        toLocation(i) {
            // console.log(i)
            this.$nextTick(() => {
                const anchorElement = document.getElementById('home' + i);
                if (anchorElement) {
                    anchorElement.scrollIntoView({ 
                        behavior: 'smooth' 
                    });
                }
            });
        },
        toDetail(i) {
            if (i == 0) {
                this.$router.push({ name: 'assistant' });
            } else if (i == 1) {
                this.$router.push({ name: 'draw' });
            } else if (i == 2) {
                this.$router.push({ name: 'draws' });
            }
        }

    }
}
</script>
<style>
#home {
    height: 100vh;
    overflow: auto;
}

#home::-webkit-scrollbar {
    display: none;
}

#home0 {
    width: 128rem;
    margin-bottom: 2rem;
}

#home0 img {
    width: 128rem;
}

#home1 {
    width: 128rem;
}

.title {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
}

.title1 {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 3rem;
    color: #a8a8a8;
}

.product {
    display: flex;
    flex-wrap: wrap;
    width: 110rem;
    padding: 0 7rem;
    margin: 0 auto;
    /* background-color: blue; */
}

.product-box {
    width: 32rem;
    margin-right: 3rem;
    margin-bottom: 3rem;
    padding: 2rem 1rem;
    border-radius: 1rem;
    border: 0.1rem solid #a8a8a8;
    background-color: #FFFFFF;
}

.product-box:nth-child(3n) {
    margin-right: 0;
}

.product-logo {
    width: 10rem;
    margin: 0 auto;
    margin-bottom: 1rem;
}

.product-logo img {
    width: 100%;
    height: 100%;
}

.product-name {
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
    margin-bottom: 1rem;
    cursor: pointer;
}

.product-text {
    width: 32rem;
    font-size: 1.2rem;
    color: #8f8f8f;
    text-align: center;
    margin-bottom: 2rem;
    cursor: pointer;
    /* background-color: aqua */
}

.product-more {
    font-size: 1.5rem;
    color: #1689FF;
    text-align: center;
    cursor: pointer;
}
</style>