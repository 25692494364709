<template>
    <div id="assistant">
        <top-bar :title="title" :list="topList" @toLocation="toLocation"></top-bar>
        <div style="height: 90px;"></div>
        <div v-if="show">
            <div id="assistant0">
                <img src="../../assets/img/assistant.jpg" alt="">
            </div>
            <div id="assistant1">
                <img src="../../assets/img/assistant1.jpg" alt="">
            </div>
            <div id="assistant4">
                <about-us></about-us>
            </div>
        </div>
        <iframe v-else :src="src" frameborder="0" style="width:100%;height:100%;margin-top: 100px;"></iframe>
        <bottom-bar v-if="show"></bottom-bar>
    </div>
</template>
<script>
import topBar from '@/components/top-bar.vue'
import bottomBar from '@/components/bottom-bar.vue'
import aboutUs from '@/components/about-us.vue'
export default {
    components: {
        topBar,
        aboutUs,
        bottomBar
    },
    data() {
        return {
            topList: ['首页', '产品说明', '用户隐私', '隐私政策', '关于我们'],
            title: {
                logo: require('@/assets/img/Ai-zs.png'),
                name: "AI写作助手",
            },
            src: null,
            show: true
        }
    },
    onLoad() {

    },
    methods: {
        toLocation(i) {
            // console.log(i)
            this.show = true
            if (i == 2) {
                this.show = false
                this.src = 'https://ai.hbsry.com/protocol/yhxy.html';
            } else if (i == 3) {
                this.show = false
                this.src = 'https://ai.hbsry.com/protocol/yszc.html';
            }
            this.$nextTick(() => {
                const anchorElement = document.getElementById('assistant' + i);
                if (anchorElement) {
                    anchorElement.scrollIntoView({ behavior: 'smooth' });
                }
            });
        },
    }
}
</script>
<style>
#assistant {
    height: 100vh;
    overflow: auto;
}

#assistant::-webkit-scrollbar {
    display: none;
}

img {
    width: 100%;
}

.assistant0,
.assistant1 {
    width: 128rem;
}
</style>